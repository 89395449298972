.react-datepicker-popper {
  z-index: 2 !important;
}

.react-datepicker__input-container {
  width: 100%;
}

@media (max-width: 576px) {
  .react-datepicker-popper {
    margin-left: -40px;
  }
}
